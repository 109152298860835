import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccordionPanelComponent } from 'ngx-bootstrap/accordion';
import { AppComponent } from 'src/app/app.component';
import { paths } from 'src/app/config/paths.config';
import { routes } from 'src/app/config/routes.config';
import { changeRightV2 } from 'src/app/handlers/right.handler';
import { HttpService, isResultValid, translate } from 'src/app/main/http-service';
import { Pagination } from 'src/app/main/table/pagination.handler';
import { Codes, convertToBool } from 'src/app/main/table/table.handler';
import { IPageChanged } from 'src/app/main/table/table.interface';
import { IManagerListResponse } from 'src/app/medtracs-entities/admin/maintenance/manager/managers.list.response.entity';
import { IGetReasons } from 'src/app/medtracs-entities/admin/maintenance/user-rights/get.reasons.entity';
import { IGetUserRights as IUserRightsAdmin } from 'src/app/medtracs-entities/admin/maintenance/user-rights/get.user.rights.entity';
import { IReasonsResponse as IReasonsResAdmin } from 'src/app/medtracs-entities/admin/maintenance/user-rights/reasons.response.entity';
import { IUserRightsResponse as IRightsResAdmin } from 'src/app/medtracs-entities/admin/maintenance/user-rights/user.rights.response.entity';
import { IGetUserRights as IUserRightsSys } from 'src/app/medtracs-entities/sysadmin/maintenance/user-rights/get.user.rights.entity';
import { IReasonsResponse as IReasonsResSysadmin } from 'src/app/medtracs-entities/sysadmin/maintenance/user-rights/reasons.response.entity';
import { IUserRightsResponse as IRightsResSysadmin } from 'src/app/medtracs-entities/sysadmin/maintenance/user-rights/user.rights.response.entity';

interface ReasonCheckbox {
   id: string;
   value: boolean;
   disabled: boolean;
}

@Component({
   selector: 'app-user-rights-accordion',
   templateUrl: './user-rights-accordion.component.html',
   styleUrls: ['./user-rights-accordion.component.css'],
})
export class UserRightsAccordionComponent implements OnInit {
   @Input() sysView = false;

   @Input() adminView = false;

   @Input() pagination: Pagination | undefined;

   @Input() paginationFilter: any = {};

   _userRights: (IUserRightsAdmin | IUserRightsSys)[];

   @Input() set userRights(userRights: (IUserRightsAdmin | IUserRightsSys)[]) {
      this._userRights = userRights;
      this.search();
   }

   @Input() searchVisible = false;

   filteredUserRights: (IUserRightsAdmin | IUserRightsSys)[] = [];

   searchText = '';

   filter = {};

   service: HttpService;

   countryService: HttpService;

   reasonService: HttpService;

   headers: Headers;

   codes: Codes;

   isSuper = false;

   countryList  = [];

   // eslint-disable-next-line no-useless-constructor
   constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router) {}

   ngOnInit(): void {
      this.isSuper = AppComponent.isRight('super');
      if (this.pagination === undefined) {
         this.pagination = new Pagination(0, translate, this.route);
      }
      if (this.adminView) {
         this.service = new HttpService(
            this.http,
            routes.admin.maintenances.userrights,
            this.router,
            this.route,
         );
      } else if (this.sysView) {
         this.service = new HttpService(
            this.http,
            routes.sysadmin.maintenances.users.rights,
            this.router,
            this.route,
         );
      }
      this.reasonService = new HttpService(this.http, routes.masterfiles.decommissionreasons);
      this.countryService = new HttpService(this.http, routes.masterfiles.country);
      this.route.params.subscribe((paramMap) => {
         if (paramMap.userId) {
            this.filter = { userId: paramMap.userId };
         }
         this.getRights();
      });

      this.getCountries();
   }

   getCountries(): void {
      this.countryService.get<{countries: {code: string, name: string}[]}>({}, (res) => {
         if (isResultValid(res)) {
            this.countryList = res.body.countries.map((c) => c.code);
         }
      });
   }

   getRights(): void {
      const filter = {
         ...this.paginationFilter,
         ...this.filter,
         ...this.pagination.aggr(),
         right: this.sysView ? 'SYS' : undefined,
      };
      this.service.get<IRightsResAdmin | IRightsResSysadmin>(filter, (res) => {
         if (isResultValid(res)) {
            this._userRights = res.body.rights;
            this.pagination.total(res.body.count);
            this._userRights.forEach(
               (right) => (right.sys = right.sys === null ? undefined : right.sys),
            );
            convertToBool(this._userRights, [
               'sys',
               'admin',
               'manager',
               'language',
               'aggregate',
               'revert',
            ]);
            convertToBool(this._userRights, ['task'], undefined, true, 9);
         } else {
            this._userRights = [];
            this.pagination.total(0);
         }
         this.search();
      });
   }

   onPageChanged(event: IPageChanged): void {
      this.pagination.page(event, () => this.getRights());
   }

   decommissionRsns: ReasonCheckbox[] = [];

   reversalRsns: ReasonCheckbox[] = [];

   reasonLabels: string[] = [];

   onToggle(
      event: MouseEvent,
      userRight: IUserRightsAdmin | IUserRightsSys,
      accordion: AccordionPanelComponent,
   ): void {
      if (accordion.isOpen) {
         accordion.isOpen = false;
      } else {
         let counter = 0;

         counter++;
         this.getManageredUsers(userRight, (resp: IManagerListResponse) => {
            this.managedUsers.push(...resp.managers.map((m) => m.userId));
            if (--counter === 0) {
               accordion.isOpen = true;
            }
         });

         counter++;
         const callback = (reasons: IGetReasons[]) => {
            this.setReasons(reasons, userRight);
            if (--counter === 0) {
               accordion.isOpen = true;
            }
         };
         this.reasonService.get<IReasonsResAdmin | IReasonsResSysadmin>(
            this.sysView ? { divisionType: (userRight as IUserRightsSys).divType } : {},
            (res) => {
               if (isResultValid(res)) {
                  callback(res.body.reasons);
               } else {
                  callback([]);
               }
            },
         );
      }
      event.stopPropagation();
   }

   private setReasons(reasons: IGetReasons[], userRight: IUserRightsAdmin): void {
      this.reasonLabels = [];
      this.decommissionRsns = [];
      this.reversalRsns = [];

      reasons.forEach((reason) => {
         const decommValue = userRight.decommRsn.split(',').includes(reason.choutRsn);
         const revertValue = userRight.revertRsn.split(',').includes(reason.choutRsn);
         if (reason.decomm || reason.revert) {
            this.reasonLabels.push(`${reason.name} (${reason.descr})`);
         }
         if (reason.decomm) {
            if (!reason.revert) {
               this.reversalRsns.push({
                  id: reason.choutRsn,
                  value: revertValue,
                  disabled: true,
               });
            }
            this.decommissionRsns.push({
               id: reason.choutRsn,
               value: decommValue,
               disabled: false,
            });
         }
         if (reason.revert) {
            if (!reason.decomm) {
               this.decommissionRsns.push({
                  id: reason.choutRsn,
                  value: decommValue,
                  disabled: true,
               });
            }
            this.reversalRsns.push({
               id: reason.choutRsn,
               value: revertValue,
               disabled: false,
            });
         }
      });
   }

   /** update reasons array in order to update checkbox field in table */
   private updateReasons(reasonType: 'decommRsn' | 'revertRsn', code: string): void {
      if (reasonType === 'decommRsn') {
         const reason = this.decommissionRsns.find((rsn) => rsn.id === code);
         if (reason) {
            reason.value = !reason.value;
         }
      } else if (reasonType === 'revertRsn') {
         const reason = this.reversalRsns.find((rsn) => rsn.id === code);
         if (reason) {
            reason.value = !reason.value;
         }
      }
   }

   managedUsers: string[] = [];

   getManageredUsers(
      userRight: IUserRightsSys | IUserRightsAdmin,
      callback: (mlist: IManagerListResponse) => void,
   ): void {
      this.managedUsers = [];
      const params = {
         ...(this.sysView ? { right: 'SYS' } : {}),
         ...(userRight['compId'] !== undefined ? { companyId: userRight['compId'] } : {}),
         divisionId: userRight.divId,
         managerId: userRight.userId,
         skip: 0,
         limit: 100,
      };

      this.http
         .get<IManagerListResponse>(routes.sysadmin.maintenances.users.manager, {
            params,
         })
         .subscribe(callback);
   }

   editManagerSettings(userRight: IUserRightsAdmin): void {
      if (this.sysView) {
         this.router.navigate([
            paths.sysadmin,
            paths.sysadmins.maintenance,
            paths.sysadmins.maintenances.user,
            paths.sysadmins.maintenances.users.manager,
            userRight.divId,
         ]);
      } else if (this.adminView) {
         this.router.navigate([
            paths.admin,
            paths.admins.maintenance,
            paths.admins.maintenances.manager,
            userRight.userId,
         ]);
      }
   }

   onCheck(event: MouseEvent, fieldName: string, userRight: IUserRightsAdmin): void {
      if (fieldName !== 'task') {
         // taskoknál a custom checkbox miatt nem lehet megoldani,
         // hogy csak a valid response megérkezése után állítsuk be
         // a checkbox értékét
         event.preventDefault();
         event.stopPropagation();
      }
      let value = userRight[fieldName];
      if (value.indeterminate !== undefined) {
         const indeterminate = !!(!value.value && !value.indeterminate);
         const checked = !!(!value.value && value.indeterminate);
         value.indeterminate = indeterminate;
         value.value = checked;
      } else {
         value = !value;
      }
      this.onCheckClick(userRight, fieldName, value, () => (userRight[fieldName] = value));
   }

   /** use with comma separated values like reasons and countries */
   onCodeCheck(
      event: MouseEvent,
      code: string,
      userRight: IUserRightsAdmin,
      field: 'countries' | 'decommRsn' | 'revertRsn',
   ): void {
      // prevent checkbox to be checked until we got a valid response from server
      event.stopPropagation();
      event.preventDefault();
      const codes = userRight[field]
         .trim()
         .split(',')
         // filter out falsy values like empty strings
         .filter((c) => c);
      const index = codes.findIndex((c) => c === code);

      if (index !== -1) {
         codes.splice(index, 1);
      } else {
         codes.push(code);
      }

      this.service.patch<any>(
         '',
         {
            userId: userRight.userId,
            divisionId: userRight.divId,
            [field]: codes.join(','),
         },
         (res) => {
            if (isResultValid(res)) {
               userRight[field] = codes.join(',');
               if (field === 'decommRsn' || field === 'revertRsn') {
                  this.updateReasons(field, code);
               }
            }
         },
      );
   }

   search(): void {
      if (!this.searchText) {
         this.filteredUserRights = [...this._userRights];
         return;
      }
      if (this.adminView) {
         this.filteredUserRights = this._userRights.filter((u) =>
            u.userId.toLowerCase().includes(this.searchText.toLowerCase()),
         );
      } else if (this.sysView) {
         this.filteredUserRights = (this._userRights as IUserRightsSys[]).filter(
            (u) =>
               u.userId.toLowerCase().includes(this.searchText.toLowerCase()) ||
               u.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
               u.divName.toLowerCase().includes(this.searchText.toLowerCase()),
         );
      }
   }

   private onCheckClick(
      userRight: IUserRightsSys | IUserRightsAdmin,
      fieldName: string,
      value: any,
      callback?: () => any,
   ) {
      const patch = { divisionId: userRight.divId, userId: userRight.userId };
      patch[fieldName] = value;
      if (patch[fieldName].indeterminate !== undefined) {
         if (patch[fieldName].indeterminate) {
            patch[fieldName] = 9;
         } else {
            patch[fieldName] = patch[fieldName].value;
         }
      }
      if (fieldName === 'language') {
         patch[fieldName] = Number(patch[fieldName]);
      }
      this.service.patch<any>('', patch, (res) => {
         if (isResultValid(res)) {
            if (fieldName === 'manager') {
               changeRightV2('manager', userRight, fieldName);
            }
            callback();
         }
      });
   }

   trackUserRight(index, userRight: IUserRightsSys | IUserRightsAdmin): string {
      return `${userRight.userId}-${userRight.divId}`;
   }

   navigateToSearch(): void {
      this.router.navigate([
         paths.sysadmin,
         paths.sysadmins.search,
         paths.sysadmins.searches.userRights,
      ]);
   }
}
