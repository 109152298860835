export interface ISessionGet {
   USER_ID: string;
   COMP_ID: number;
   DIV_ID: number;
   LANG: string;
   ACT: boolean;
   LAST_ACT: Date;
   TERM_ID: number;
   VRSN: string;
   SYSDATE: Date;
   WEB_ACT: boolean;
   DIV_ACT: boolean;
   COMP_WEB_ACT: boolean;
   COUNTRIES: string;
   BLOCKED: boolean;
   COUNTRY: string;
   IP_ADDRESS: string;

   SUPER: boolean;
   SYS: boolean;
   ADMIN: boolean;
   LANGUAGE: boolean;
   MANAGER: boolean;
}

// eslint-disable-next-line no-shadow
export enum ProviderEnum {
   SOLIDOSFT = 'solidsoft',
   ARVATO = 'arvato',
}

export interface IRights {
   divId: number;
   divName: string;
   super: boolean;
   sys: boolean;
   admin: boolean;
   manager: boolean;
   language: boolean;
}

export type SessionRights = Array<keyof IRights>;

export interface ISession {
   sessionId: string;
   // cookie: any;
   user: {
      userId: string;
      lang: string;
      lastDiv: number;
      country: string;
      pdfReport: boolean;
      canDownloadDocs: boolean;
      provider: ProviderEnum;
   };
   broadcasts: {
      value: string;
   }[];
   rights: Array<IRights>;
   acceptLanguages: string;
}
