/* eslint-disable no-use-before-define */
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import _ from 'lodash';
import { routes } from 'src/app/config/routes.config';
import { Icons } from 'src/app/handlers/icon.handler';
import { HttpService, isResultValid, translate } from 'src/app/main/http-service';
import { Body, ControlType } from 'src/app/main/modal/body.handler';
import { ButtonType } from 'src/app/main/modal/button.handler';
import { InitButton } from 'src/app/main/modal/modal.button';
import { ModalHandler } from 'src/app/main/modal/modal.handler';
import { translateModal } from 'src/app/main/modal/modal.translate';
import { Pagination } from 'src/app/main/table/pagination.handler';
import { Codes, Headers, addButton, center } from 'src/app/main/table/table.handler';
import { IButtonEvent, IPageChanged } from 'src/app/main/table/table.interface';
import { IScheduledMaintenance } from 'src/app/medtracs-entities/sysadmin/maintenance/scheduled-maintenance/get.maintenance.entity';
import { translateHeaders } from '../../../main/table/table.translate';

@Component({
   selector: 'scheduled-maintenance',
   templateUrl: './scheduled-maintenance.component.html',
   styleUrls: ['./scheduled-maintenance.component.css'],
})
export class ScheduledMaintenanceComponent implements OnInit {
   service: HttpService;

   icons = Icons;

   headers = new Headers(['Start date', 'Start time', 'End date', 'End time', 'Created at', 'Created by', 'Updated at', 'Updated by', center('Modify'), center('Delete')]);

   codes: Codes = new Codes(['startDate', 'startTime', 'endDate', 'endTime', 'createdAt', 'createdBy', 'updatedAt', 'updatedBy', center('modify'), center('delete')]);

   pagination: Pagination;

   maintenances: IScheduledMaintenance[] = [];

   confirmHandler: ModalHandler = new ModalHandler();   

   hideCardBody = true;

   edit = false;

   timeRegex = "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$";

   maintenance = new UntypedFormGroup({
      'id': new UntypedFormControl({ value: '', disabled: false }, []),
      'startDate': new UntypedFormControl({ value: '', disabled: false }, [Validators.required]),
      'startTime': new UntypedFormControl({ value: '', disabled: false }, [Validators.required, Validators.pattern(this.timeRegex)]),
      'endDate': new UntypedFormControl({ value: '', disabled: false }, [Validators.required]),
      'endTime': new UntypedFormControl({ value: '', disabled: false }, [Validators.required, Validators.pattern(this.timeRegex)]),
      'newsPublishDate': new UntypedFormControl({ value: '', disabled: false }, []),
      'newsPublishTime': new UntypedFormControl({ value: '', disabled: false }, [Validators.pattern(this.timeRegex)]),
      'newsTitle': new UntypedFormControl({ value: '', disabled: false }, []),
      'newsBody': new UntypedFormControl({ value: '', disabled: false }, []),
      'broadcastStartDate': new UntypedFormControl({ value: '', disabled: false }, []),
      'broadcastStartTime': new UntypedFormControl({ value: '', disabled: false }, [Validators.pattern(this.timeRegex)]),
      'broadcastEndDate': new UntypedFormControl({ value: '', disabled: false }, []),
      'broadcastEndTime': new UntypedFormControl({ value: '', disabled: false }, [Validators.pattern(this.timeRegex)]),
      'broadcastEng': new UntypedFormControl({ value: '', disabled: false }, []),
      'broadcastHun': new UntypedFormControl({ value: '', disabled: false }, []),
      'broadcastPol': new UntypedFormControl({ value: '', disabled: false }, []),
      'broadcastRon': new UntypedFormControl({ value: '', disabled: false }, []),
   });

   constructor(public route: ActivatedRoute,private http: HttpClient) {
      this.service = new HttpService(this.http, routes.sysadmin.maintenances.scheduledMaintenance);
      translateHeaders(this.headers, translate);
   }

   ngOnInit(): void {
      this.pagination = new Pagination(0, translate, this.route);
      this.getMaintenances();
   }

   changeHide(): void {
      this.hideCardBody = !this.hideCardBody;
   }

   getMaintenances(): void {
      this.service.get<{maintenances: IScheduledMaintenance[], count: number}>({...this.pagination.aggr()}, (result) => {
         if (result) {
            if (isResultValid(result)) {
               this.pagination.total(result.body.count);
               this.maintenances = result.body.maintenances;
               addButton(this.maintenances, 'modify', translate('Modify'));
               addButton(this.maintenances, 'delete', translate('Delete'), ["btn-danger"]);
               
            }
         }
      });
   }

   onSubmit(): void {
      const body = Object.keys(this.maintenance.value)
            .filter((k) => this.maintenance.value[k] != null && this.maintenance.value[k] !== "")
            .reduce((a, k) => ({ ...a, [k]: this.maintenance.value[k] }), {});
      if(this.edit){
         this.service.patch('', body, (result) => {
            if(result) {
               if(isResultValid(result)) {
                  this.getMaintenances();
                  this.onReset();
               }
            }
         });
      } else {
         this.service.post(body, (result) => {
            if(result) {
               if(isResultValid(result)) {
                  this.getMaintenances();
                  this.onReset();
               }
            }
         });
      }
   }

   onButtonClick(event: IButtonEvent<IScheduledMaintenance>): void {
      if(typeof event.id === 'object') {
         if(event.id.str === 'delete') {
            this.onDeleteClicked(event);
         } else if(event.id.str === 'modify') {
            this.onModifyClicked(event);
         }
      }  
   }

   onDeleteClicked(event: IButtonEvent<IScheduledMaintenance>): void {
      this.confirmHandler.title = "Are you sure to delete this scheduled maintenance event and all related news and broadcasts?";
      [this.confirmHandler.buttons] = InitButton(
         { type: ButtonType.OkCancel, prefix: 'confirm' },
         1,
      );
      this.confirmHandler.body = [
         { id: 'id', value: event.row.id, type: ControlType.formInline, hidden: true },
         {
            id: 'startDate',
            value: event.row.startDate,
            type: ControlType.formInline,
            label: 'Start date:',
         },
         {
            id: 'startTime',
            value: event.row.startTime,
            type: ControlType.formInline,
            label: 'Start time:',
         },
         {
            id: 'endDate',
            value: event.row.endDate,
            type: ControlType.formInline,
            label: 'End date:',
         },
         {
            id: 'endTime',
            value: event.row.endTime,
            type: ControlType.formInline,
            label: 'End time:',
         },
      ];
      this.confirmHandler.change.next(
         translateModal(this.confirmHandler, translate),
      );
      this.confirmHandler.event.next();
      
   }

   onModifyClicked(event: IButtonEvent<IScheduledMaintenance>): void {
      this.edit = true;
      this.hideCardBody = false;
      const maintenance = this.convertPropsToDate(event.row, ['startDate', 'endDate', 'newsPublishDate', 'broadcastStartDate', 'broadcastEndDate']);
      this.maintenance.patchValue(maintenance);
   }

   convertPropsToDate(input: unknown, props: string[]): unknown {
      const result = _.cloneDeep(input);
      props.forEach((prop) => {
         if(input[prop]) {
            result[prop] = new Date(input[prop]);
         }
      });
      return result;
   }

   onPageChanged(event: IPageChanged): void {
      this.pagination.page(event, () => this.getMaintenances());
   }

   onRemoveConfirmed(event: Body): void {
      if (event.getKey() === 'confirm-ok') {
         this.service.delete('', {id: event.getPatchValues().id}, (result) => {
            if(result) {
               if(isResultValid(result)) {
                  this.getMaintenances();
               }
            }
         });
      }
   }

   onReset(): void {
      this.maintenance.reset();
      this.edit = false;
   }

   isFormValid(): boolean {
      return this.maintenance.valid ;
   }
}
