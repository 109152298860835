export const baseApiUrl = '/mts/v4';

export const routes = {
   session: `${baseApiUrl}/session`,
   system: `${baseApiUrl}/system`,
   migration: '/api/migration',
   emailConfirm: `${baseApiUrl}/communication/emails`,
   emailUnsubscribe: `${baseApiUrl}/communication/emails`,
   csv: `${baseApiUrl}/csv`,
   csvDownload: `${baseApiUrl}/csv-download`,
   download: `${baseApiUrl}/communication/documents`,
   docs: `${baseApiUrl}/communication/documents`,
   validationDocumentss: `${baseApiUrl}/communication/validation-documents`,
   news: `${baseApiUrl}/communication/news`,
   settings: `${baseApiUrl}/system`,
   companies: `${baseApiUrl}/master-files/companies`,
   divisions: `${baseApiUrl}/master-files/divisions`,
   users: `${baseApiUrl}/master-files/users`,
   statusReasons: `${baseApiUrl}/turnover/status-reasons`,
   pdfReport: `${baseApiUrl}/pdf`,
   sysadmin: {
      maintenances: {
         testpack: `${baseApiUrl}/sysadmin/test-pack`,
         statuses: `${baseApiUrl}/sysadmin/system-status`,
         systemlog: `${baseApiUrl}/statistics/system-log`,
         users: {
            divisions: `${baseApiUrl}/master-files/rights`,
            rights: `${baseApiUrl}/master-files/rights`,
            manager: `${baseApiUrl}/master-files/managers`,
         },
         auditlog: `${baseApiUrl}/statistics/audit-log`,
         dictionary: `${baseApiUrl}/sysadmin/dictionary`,
         scheduledMaintenance: `${baseApiUrl}/sysadmin/scheduled-maintenance`,
         applicationVersion: `${baseApiUrl}/sysadmin/app-version`,
         portalVersion: `${baseApiUrl}/sysadmin/portal-version`,
         resendRecords: `${baseApiUrl}/sysadmin/resend-records`,
      },
      masterfiles: {
         company: `${baseApiUrl}/master-files/companies`,
         division: `${baseApiUrl}/master-files/divisions`,
         certificates: `${baseApiUrl}/master-files/certificates`,
         solidsoftCredentials: `${baseApiUrl}/master-files/solidsoft-credentials`,
         certificatesDetails: `${baseApiUrl}/master-files/certificates-details`,
         certificateshistory: `${baseApiUrl}/master-files/certificates`,
         decommissionreasons: `${baseApiUrl}/master-files/checkout-reasons`,
         users: `${baseApiUrl}/master-files/users`,
         terminals: `${baseApiUrl}/master-files/terminals`,
         products: `${baseApiUrl}/master-files/products`,
         nmvserrors: `${baseApiUrl}/master-files/error-codes`,
         wholesalers: `${baseApiUrl}/master-files/wholesalers`,
         tasknames: `${baseApiUrl}/master-files/tasknames`,
         apiKeys: `${baseApiUrl}/master-files/api-keys`,
      },
      communications: {
         helpdesk: `${baseApiUrl}/communication/helpdesk`,
         contacts: `${baseApiUrl}/communication/contacts`,
         news: `${baseApiUrl}/communication/news`,
         newsDetails: `${baseApiUrl}/communication/news-details`,
         companyEmail: `${baseApiUrl}/sysadmin/company-email`,
         broadcasts: `${baseApiUrl}/sysadmin/broadcasts`,
         language: `${baseApiUrl}/communication/language`,
      },
      statistics: {
         postgres: `${baseApiUrl}/statistics/postgres`,
         transactionnumbers: `${baseApiUrl}/statistics/transaction-numbers`,
         recordnumbers: `${baseApiUrl}/statistics/record-numbers`,
         alerts: `${baseApiUrl}/statistics/alerts`,
         runtime: `${baseApiUrl}/statistics/runtime`,
         statuses: `${baseApiUrl}/statistics/statuses`,
         nmvs: `${baseApiUrl}/statistics/nmvs`,
      },
      search: {
         sessions: `${baseApiUrl}/turnover/sessions`,
         tasks: `${baseApiUrl}/turnover/tasks`,
         transactions: `${baseApiUrl}/turnover/transactions`,
         records: `${baseApiUrl}/turnover/records-data`,
         datamatrix: `${baseApiUrl}/turnover/datamatrix`,
         recordsdata: `${baseApiUrl}/turnover/records-data`,
         statusReasons: `${baseApiUrl}/turnover/status-reasons`,
         pm2Log: `${baseApiUrl}/turnover/log`,
      },
   },
   translation: `${baseApiUrl}/communication/translation`,
   translationExport: `${baseApiUrl}/communication/translation-export`,
   user: {
      division: `${baseApiUrl}/master-files/divisions`,
   },
   masterfiles: {
      managedusers: `${baseApiUrl}/master-files/users`,
      decommissionreasons: `${baseApiUrl}/master-files/checkout-reasons`,
      nmvserrors: `${baseApiUrl}/master-files/error-codes`,
      products: `${baseApiUrl}/master-files/products`,
      terminals: `${baseApiUrl}/master-files/terminals`,
      wholesalers: `${baseApiUrl}/master-files/wholesalers`,
      parameters: `${baseApiUrl}/master-files/parameters`,
      ipGuard: `${baseApiUrl}/master-files/ip-guard`,
      country: `${baseApiUrl}/master-files/country`,
   },
   admin: {
      nvmsPassword: `${baseApiUrl}/admin/password`,
      divisions: `${baseApiUrl}/admin/divisions`,
      masterfiles: {
         divisions: `${baseApiUrl}/master-files/divisions`,
         users: `${baseApiUrl}/master-files/users`,
         terminals: `${baseApiUrl}/master-files/terminals`,
         certificates: `${baseApiUrl}/master-files/certificates`,
         solidsoftCredentials: `${baseApiUrl}/master-files/solidsoft-credentials`,
         certificatesDetails: `${baseApiUrl}/master-files/certificates-details`,
         tasknames: `${baseApiUrl}/master-files/tasknames`,
         apiKeys: `${baseApiUrl}/master-files/api-keys`,
      },
      maintenances: {
         userdivisions: `${baseApiUrl}/master-files/rights`,
         userrights: `${baseApiUrl}/master-files/rights`,
         auditlog: `${baseApiUrl}/statistics/audit-log`,
         manager: `${baseApiUrl}/master-files/managers`,
      },
      communications: {
         helpdesk: `${baseApiUrl}/communication/helpdesk`,
         contacts: `${baseApiUrl}/communication/contacts`,
         news: `${baseApiUrl}/communication/news`,
      },
   },
   history: {
      users: `${baseApiUrl}/history/users`,
      rights: `${baseApiUrl}/history/rights`,
      managers: `${baseApiUrl}/history/managers`,
      product: `${baseApiUrl}/history/products`,
   },
   statistics: {
      transactionnumbers: `${baseApiUrl}/statistics/transaction-numbers`,
      recordnumbers: `${baseApiUrl}/statistics/record-numbers`,
      runtime: `${baseApiUrl}/statistics/runtime`,
      alerts: `${baseApiUrl}/statistics/alerts`,
      statuses: `${baseApiUrl}/statistics/statuses`,
      nmvs: `${baseApiUrl}/statistics/nmvs`,
      batch: `${baseApiUrl}/statistics/batch`,
      recordProctTimes: `${baseApiUrl}/sysadmin/record-proc-time`,
   },
   search: {
      sessions: `${baseApiUrl}/turnover/sessions`,
      datamatrix: `${baseApiUrl}/turnover/datamatrix`,
      tasks: `${baseApiUrl}/turnover/tasks`,
      records: `${baseApiUrl}/turnover/records-data`,
      transactions: `${baseApiUrl}/turnover/transactions`,
      solidsoftTransactions: `${baseApiUrl}/turnover/solidsoft-transactions`,
      transactionDetails: `${baseApiUrl}/turnover/transactions-details`,
      recordsdata: `${baseApiUrl}/turnover/records-data`,
      alertDetails: `${baseApiUrl}/nams`,
      archiveTasks: `${baseApiUrl}/turnover/archive-tasks`,
   },
};
