<div class="card border-dark">
   <div class="card-header" id="search-header" style="margin-bottom: 1rem">
      <div class="form-inline">
         <b>{{ 'User rights' | translate }}</b>
         <div class="col" style="text-align: right">
            <input
               class="form-control"
               style="height: 2rem"
               type="text"
               [placeholder]="'Filter' | translate"
               [(ngModel)]="searchText"
               (keyup)="search()"
            />
            <button
               *ngIf="sysView && searchVisible"
               class="btn btn-primary btn-sm"
               style="margin-left: 0.25rem"
            >
               <lib-icon [content]="'fas:faSearch'" (iClicked)="navigateToSearch()"></lib-icon>
            </button>
         </div>
      </div>
   </div>
   <div class="card-body" style="padding: 0">
      <accordion [closeOthers]="true">
         <accordion-group
            #accordion
            *ngFor="let userRight of filteredUserRights; trackBy: trackUserRight"
         >
            <button
               class="btn btn-block justify-content-between d-flex w-100 shadow-none"
               accordion-heading
               type="button"
               (click)="onToggle($event, userRight, accordion)"
            >
               <div [ngStyle]="{ flex: adminView ? '100%' : '33.33%' }"
                  ><b>{{ userRight.userId }}</b></div
               >
               <ng-container *ngIf="sysView">
                  <div style="flex: 33.33%"
                     ><b>{{ userRight.name }}</b></div
                  >
                  <div style="flex: 33.33%"
                     ><b>{{ userRight.divName }}</b></div
                  >
               </ng-container>
            </button>
            <h5>{{ 'Roles and permissions' | translate }}:</h5>
            <table class="table roles-and-permissions">
               <thead>
                  <tr>
                     <th scope="col" *ngIf="isSuper && !adminView">{{ 'Sysadmin' | translate }}</th>
                     <th scope="col" *ngIf="sysView">{{ 'Admin' | translate }}</th>
                     <th scope="col">{{ 'Manager' | translate }}</th>
                     <th scope="col">{{ 'Change lang.' | translate }}</th>
                     <th scope="col">{{ 'Task auth.' | translate }}</th>
                     <th scope="col">{{ 'Revert others' | translate }}</th>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td *ngIf="isSuper && !adminView"
                        ><div class="form-check">
                           <input
                              class="form-check-input position-static"
                              type="checkbox"
                              [checked]="userRight.sys"
                              (click)="onCheck($event, 'sys', userRight)" /></div
                     ></td>
                     <td *ngIf="sysView"
                        ><div class="form-check">
                           <input
                              class="form-check-input position-static"
                              type="checkbox"
                              [checked]="userRight.admin"
                              (click)="onCheck($event, 'admin', userRight)" /></div
                     ></td>
                     <td
                        ><div class="form-check">
                           <input
                              class="form-check-input position-static"
                              type="checkbox"
                              [checked]="userRight.manager"
                              (click)="onCheck($event, 'manager', userRight)" /></div
                     ></td>
                     <td
                        ><div class="form-check">
                           <input
                              class="form-check-input position-static"
                              type="checkbox"
                              [checked]="userRight.language"
                              (click)="onCheck($event, 'language', userRight)" /></div
                     ></td>
                     <td>
                        <div class="custom-control custom-checkbox">
                           <input
                              type="checkbox"
                              class="custom-control-input"
                              (click)="onCheck($event, 'task', userRight)"
                              [indeterminate]="userRight.task.indeterminate"
                              [checked]="userRight.task.value"
                              [id]="userRight.userId + '-' + userRight.divId + '-' + 'task'"
                           />
                           <label
                              class="custom-control-label"
                              [for]="userRight.userId + '-' + userRight.divId + '-' + 'task'"
                           ></label>
                        </div>
                     </td>
                     <td
                        ><div class="form-check">
                           <input
                              class="form-check-input position-static"
                              type="checkbox"
                              [checked]="userRight.revert"
                              (click)="onCheck($event, 'revert', userRight)" /></div
                     ></td>
                  </tr>
               </tbody>
            </table>
            <ng-container *ngIf="userRight.sys && isSuper && !adminView">
               <h5 class="mt-4">{{ 'Countries' | translate }}:</h5>
               <table class="table countries">
                  <thead>
                     <tr>
                        <th *ngFor="let country of countryList" scope="col">{{country}}</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td *ngFor="let country of countryList"
                           ><div class="form-check">
                              <input
                                 class="form-check-input position-static"
                                 type="checkbox"
                                 [checked]="userRight.countries.split(',').includes(country)"
                                 (click)="
                                    onCodeCheck($event, country, userRight, 'countries')
                                 " /></div
                        ></td>
                     </tr>
                  </tbody>
               </table>
            </ng-container>
            <h5 class="mt-4">{{ 'Reasons' | translate }}:</h5>
            <table class="table reasons-table">
               <thead>
                  <tr>
                     <th scope="col"></th>
                     <th scope="col">{{ 'Decommission reasons' | translate }}</th>
                     <th scope="col">{{ 'Reversal reasons' | translate }}</th>
                  </tr>
               </thead>
               <tbody>
                  <tr *ngFor="let label of reasonLabels; let i = index">
                     <th scope="row">{{ label }}</th>
                     <td>
                        <div class="form-check">
                           <input
                              class="form-check-input position-static"
                              type="checkbox"
                              [ngModel]="decommissionRsns[i].value"
                              (click)="
                                 onCodeCheck($event, decommissionRsns[i].id, userRight, 'decommRsn')
                              "
                              [disabled]="decommissionRsns[i].disabled"
                           />
                        </div>
                     </td>
                     <td>
                        <div class="form-check">
                           <input
                              class="form-check-input position-static"
                              type="checkbox"
                              [ngModel]="reversalRsns[i].value"
                              (click)="
                                 onCodeCheck($event, reversalRsns[i].id, userRight, 'revertRsn')
                              "
                              [disabled]="reversalRsns[i].disabled"
                           />
                        </div>
                     </td>
                  </tr>
               </tbody>
            </table>
            <ng-container *ngIf="userRight.manager">
               <h5 class="mt-4">{{ 'Manager settings' | translate }}:</h5>
               <ng-container *ngIf="managedUsers.length > 0; else noManagedUser">
                  <b>Managed Users:</b>
                  <ul style="margin-top: 0.5rem">
                     <li *ngFor="let managedUser of managedUsers">{{ managedUser }}</li>
                  </ul>
               </ng-container>
               <ng-template #noManagedUser>
                  <div style="margin: 0.5rem 0 1rem 0"
                     ><b>{{ 'There are no managed user' | translate }}</b></div
                  >
               </ng-template>
               <button
                  class="btn btn-primary"
                  type="button"
                  (click)="editManagerSettings(userRight)"
                  >{{ 'Edit manager settings' | translate }}</button
               >
            </ng-container>
         </accordion-group>
      </accordion>

      <div class="col" [ngStyle]="{ 'text-align': pagination.align }" style="margin: 1rem 0">
         <div style="display: inline-flex">
            <pagination
               [ngModel]="pagination.currentPage"
               [totalItems]="pagination.totalItems"
               [itemsPerPage]="pagination.itemsPerPage"
               [nextText]="pagination.nextText"
               [previousText]="pagination.previousText"
               [maxSize]="pagination.maxSize"
               [rotate]="pagination.rotate"
               [boundaryLinks]="pagination.boundaryLinks"
               [firstText]="pagination.firstText"
               [lastText]="pagination.lastText"
               (pageChanged)="onPageChanged($event)"
            >
            </pagination>
         </div>
      </div>
   </div>
</div>
