<lib-table
   *ngIf="canDownloadDocs"
   [headers]="portalHeaders"
   [codes]="portalCodes"
   [rows]="portalReleases"
   [readOnly]="true"
   [search]="false"
   (iconEvent)="onIconClick($event)"
   [label]="'Portal releases' | translate"
>
</lib-table>

<lib-table
   [headers]="appHeaders"
   [codes]="appCodes"
   [rows]="appReleases"
   [readOnly]="true"
   [search]="false"
   (iconEvent)="onIconClick($event)"
   [label]="'Application releases' | translate"
>
</lib-table>
