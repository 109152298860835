/* eslint-disable no-shadow */
export enum Icons {
   plus = 'fas:faPlus',
   check = 'fas:faCheck', // kiszállítva
   editS = 'fas:faEdit',
   editR = 'far:farEdit',
   delete = 'fas:faTimes',
   download = 'fas:faDownload',
   pdf = 'fas:faFilePdf',

   commentS = 'fas:faComment',
   commentR = 'far:faComment',
   commentsS = 'fas:faComments',
   commentsR = 'far:faComments',
   commentDotsS = 'fas:faCommentDots',
   commentDotsR = 'far:faCommentDots',

   dollarSign = 'fas:faDollarSign',
   signOutAlt = 'fas:faSignOutAlt',
   key = 'fas:faKey',

   trashAlt = 'fas:faTrashAlt', // törölve
   clipboardCheck = 'fas:faClipboardCheck',
   clipboardList = 'fas:faClipboardList', // feldolgozásra vár
   dolly = 'fas:faDolly',
   thumbtack = 'fas:faThumbtack', // evidenciába
   truck = 'fas:faTruck',

   list = 'fas:faList',

   cogs = 'fas:faCogs', // feldolgozás alatt
   box = 'fas:faBox', // feldolgozva

   info = 'fas:faInfo',
   infoCircle = 'fas:faInfoCircle',
   print = 'fas:faPrint',
   language = 'fas:faGlobeAmericas',
   home = 'fas:faHome',

   settings = 'fas:faUsersCog',
   warehouse = 'fas:faWarehouse',
   search = 'fas:faSearch',

   chevronDown = 'fas:faChevronDown',
   chevronUp = 'fas:faChevronUp',

   userEdit = 'fas:faUserEdit',
   undo = 'fas:faUndoAlt',
   receipt = 'fas:faReceipt',

   share = 'fas:faShare',
   replyAll = 'fas:faReplyAll',
}
